
a{
  text-decoration: none;
  color: inherit;
}
.navbar{
  background: none !important;
}
.header{
  background: #3698db;
}
.header-bg-image{
  background: url("shape.svg");
  background-repeat: no-repeat;
  background-size: cover; 
  
}
.header-image{
  height: 648px;
  width: auto;
}

.card{
  border-radius: 25px;
  -webkit-box-shadow: 5px 3px 26px 0px rgba(0,0,0,0.45); 
  box-shadow: 5px 3px 26px 0px rgba(0,0,0,0.45);

  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.card:hover{
  top: -10px;
}
.card-image{
  height: 200px;
  width: auto;
}
.planning-section{
  margin-top: 150px;
  background: #3698db;
}
.tripplanning-image{
  height: 648px;
  width: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.search-and-book{
  padding-left: 9rem;
}


.tripplaned-image{
  height: 648px;
  width: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.team-member{
  position: relative;
}
.team-image{
  border-radius: 50%;
  width: 250px;
  height: auto;
}


.team-text{
  position: absolute;

  top: 0;
  left: 0;

  height: 100%;
  width: 250px;

  opacity: 0;

  transition: 0.2s;

  border-radius: 50%;

  background: rgb(54,152,219);
  background: linear-gradient(90deg, rgba(54,152,219,1) 0%, rgba(9,9,121,0.5438550420168067) 68%, rgba(0,38,101,1) 100%);
}

.team-text:hover{
  opacity: 1;
}

.team-icons{
  height: 25px;
  width: auto;
}

.form-container{
  padding-top: 50px;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 30;
  background: #3698db;
  
}

.contact-us{
  background: #3698db;
}

.contact-us-bg-image{
  background: url("contact-us-shape.png");
  background-repeat: no-repeat;
  background-size: cover; 
}

.small-mail-icon{
  height: 20px;
  width: auto;
}
.big-mail-icon{
  height: 500px;
  width: auto;
}
.input-container{
  background: white;
  
  border-color: #3698db;
  border-width: 1px;
  border-radius: 30px;
  border-style: solid;

  height: 60px;
  width: 70%;

  display: flex;
  align-items: center;
  padding-left: 20px;
}

.message-input{
  outline: 0;
  border-width: 0;
  background: transparent;

  color: rgb(128, 128, 128);

  font-size: 1.5rem;

  width: 100%;

  height: 60px;
  
}
.message-input::placeholder, .message::placeholder{
  color: rgb(189, 189, 189);
  opacity: 1; 

  font-size: 1.5rem;
}

.message{

  font-size: 1.5rem;
  color: gray;

  background: transparent;
  border: 0;

  max-height: 70px;
  max-width: 450px;
  word-wrap: break-word;
  margin: 0;
  min-height: 33px;
  overflow: auto;
  position: relative;
  width: 97%;
  padding-left: 5px;

  vertical-align: top;

  outline: none;

  height: 100%;
}

.message-height{
  height: 100px;
  align-items: flex-start;
}

.message::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.message::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.message::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.send-button{
  
  background: #1b4da8;
  border-color: #1b3da8;

  width: 70% !important;
  height: 70px;

  border-radius: 35px;

  font-size: 1.3rem;

}

.answer-input{
  border: 2px solid #3698db;
  border-radius: 10px;
}

.sent-email-img{
  width: 200px;
  height:auto;
}

.back-to-site-button{

  background: #1b4da8;
  border-color: #1b3da8;

  width: 100% !important;
  height: 40px;

  border-radius: 5px;

  font-size: 0.8rem;

}

.form-container{
  width: 100%;
  background: transparent;
}

.footer{
  background: #3698db;
  padding-bottom: 20px;
   
}
.footer-logo{
  height: 70px;
  width: auto;
}

.footer-logo-text{
  font-size: 1rem;
}

.footer-icons-container{
  display: inline;
}
.footer-icon{
  width: auto;
  height: 20px;
}
.footer-list-item-location-container{
  display: flex;
}
.footer-list-item{
  color: #e9e9e9 !important;
}
.footer-list-item-logo{
  height: 20px;
  width: auto;
}

.special-padding-top{
  padding-top: 100px;
}
.special-margin-top{
  margin-top: 100px;
}
/* 3498db */


@media only screen and (max-width: 600px) {
  .header-main-text{
    text-align: center;
    font-size: 2rem;
  }
  .header-sub-text{
    text-align: center;
  }
  .card{
    margin-top: 20px;
  }
  .trip-input-main-text{
    margin-top: 20px;
    text-align: center;
  }
  .trip-input-sub-text{
    text-align: center;
  }
  .search-and-book{
    padding-left: 20px;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .search-main-text{
    text-align: center;
  }
  .search-sub-text{
    text-align: center;
  }
  .planning-section{
    margin-top: 50px;
  }
  .planning-main-text{
    text-align: center;
    margin-top: 20px;
  }
  .planning-sub-text{
    text-align: center;
  }
  .big-mail-icon{
    width: 200px;
    height: auto;
    display: flex;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .input-container{
    width: 100%;
  }
  .send-button{
    display: flex;
    margin-right: auto !important;
    margin-left: auto !important;

    justify-content: center;
    align-items: center;
  }
  .team-image{
    border-radius: 50%;
    width: 100%;
    height: auto;
  }
  
  
  .team-text{
    position: absolute;
  
    top: 0;
    left: 0;
  
    height: 100%;
    width: 100%;
  
    opacity: 0;
  
    transition: 0.2s;
  
    border-radius: 50%;

    background: rgb(54,152,219);
    background: linear-gradient(90deg, rgba(54,152,219,1) 0%, rgba(9,9,121,0.5438550420168067) 68%, rgba(0,38,101,1) 100%);
  }
  
}

.slide-left-animation{
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.slide-right-animation{
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.slide-bottom-animation{
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.slide-top-animation{
  animation: 0.5s ease-out 0s 1 slideInFromTop;
}



@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}


@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}